/* eslint-disable no-case-declarations */
import {
  PaymentStatus,
  PetPoliciesInvoice,
  PetPolicyDiscount,
  PetType,
  PetWeight,
  PolicyStatus,
  SaveQuotesToken,
  Users,
  AutorenewalStatus,
} from '@prisma/client';
import { getRenewalReadyIds } from '@utils/getRenewal';
import React from 'react';
import { MandatePaymentMethod } from '../components/Forms/Curlec/shared_data';
import { PLAN_BILL_CYCLE } from '../utils/constant';

type Address = {
  address?: string;
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  id: number;
  state?: string;
  zip?: number;
};

type BankDetails = {
  accountContact: string;
  accountName: string;
  accountNo: string;
  id: number;
  name: string;
};

export type User = {
  address: Address;
  userBankDetails: BankDetails;
  contact?: string;
  dateOfBirth?: string;
  email: string;
  fullName: string;
  id: number;
  nationality?: string;
  nickname?: string;
  nric?: string;
  passport?: string;
  role?: string;
  sex?: string;
};

export type Pet = {
  id: number;
  accident: boolean;
  accidentDetails: string;
  breed: string;
  congenitalDisease: boolean;
  congenitalDiseaseDetails: string;
  dateOfBirth: string;
  healthCondition: string[];
  healthStatus: string;
  microchipId: string;
  name: string;
  petDocumentUrls: string[];
  petPhotoUrl: string;
  sex: string;
  type: PetType;
  weight: PetWeight;
};
type PetPolicy = {
  id: number;
  burialLimit: number;
  followupTreatmentDays: number;
  maxClinicOvernightStay: number;
  name: string;
  petType: PetType;
  // This field is not always available
  price: number;
  thirdPartyLimit: number;
  monthlyPrice: number;
  yearlyPrice: number;
  freeMicroChip: boolean;
};
export type UserPetPolicy = {
  invoices?: PetPoliciesInvoice[];
  id: number;
  groupId: number;
  certificateUrl: string;
  deductiblesAmount?: number;
  paid: true;
  paidAt: string;
  pets: Pet;
  policyId?: number;
  petPolicies: PetPolicy;
  petPolicyDiscount: PetPolicyDiscount;
  startDate: string;
  endDate: Date;
  totalAmount: number;
  billCycle: PLAN_BILL_CYCLE;
  status: PolicyStatus;
  paymentStatus: PaymentStatus;
  renewPolicy?: UserPetPolicy;
  mandateAppliedAt?: Date | string;
  paymentMethod?: MandatePaymentMethod;
  paymentCardType: 'VISA' | 'MASTERCARD' | null;
  paymentCardExpiry: string | null;
  paymentCardNo: string | null;
  overdueAmount?: number;

  createdAt?: Date;
  updatedAt?: Date;
  loadingPercent?: number;
  loadingAmount?: number;

  petPoliciesInvoice: PetPoliciesInvoice[];
  user?: Users;
  billableMonth: number;
  billableAmount: number;
  quickRenewalId: SaveQuotesToken;
  partialCollect?: boolean;
  enableAutocollection?: boolean;
  isInRetryPeriod?: boolean;
  product?: string;

  conventionalRenewPolicy?: UserPetPolicy;
  cancellationRemarks?: string;
  subscriptionItemId?: number;
  autoRenewalStatus?: AutorenewalStatus;
};

type State = {
  user: User;
  userPetPolicies: UserPetPolicy[];
  loadingPolicies: boolean;
  approvedRenewalPoliciesId: number[];
};
type Action =
  | {
      type: 'SET_AUTH';
      payload: any;
    }
  | {
      type: 'LOADING_POLICIES';
      payload: boolean;
    }
  | {
      type: 'UPDATE_USER_PET_POLICY';
      payload: Partial<UserPetPolicy>;
    }
  | {
      type: 'SET_USER_PET_POLICIES';
      payload: UserPetPolicy[];
    };
type Dispatch = (action: Action) => void;
type ContextProps = {
  state: State;
  dispatch: Dispatch;
};
type UserInfoProviderProps = {
  children: React.ReactNode;
};

const UserInfoReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_AUTH':
      const address = action.payload.address;

      const addresses = (address.address || '').split('\n');

      const addressObject = {
        address1: '',
        address2: '',
      };

      if (addresses.length > 1) {
        addressObject.address1 = addresses[0];
        addressObject.address2 = addresses.slice(1).join('\n');
      } else if (address.length === 1) {
        addressObject.address1 = addresses[0];
      }

      return {
        ...state,
        user: {
          ...action.payload,
          address: {
            ...action.payload.address,
            ...addressObject,
          },
        },
      };
    case 'SET_USER_PET_POLICIES':
      return {
        ...state,
        userPetPolicies: action.payload,
        approvedRenewalPoliciesId: getRenewalReadyIds(action.payload),
      };

    case 'UPDATE_USER_PET_POLICY':
      const updatedUserPetPolicies = state.userPetPolicies.map((upp) => {
        if (upp.id === action.payload.id) {
          return {
            ...upp,
            ...action.payload,
          };
        }
        return upp;
      });

      return {
        ...state,
        userPetPolicies: updatedUserPetPolicies,
        approvedRenewalPoliciesId: getRenewalReadyIds(updatedUserPetPolicies),
      };
    case 'LOADING_POLICIES':
      return { ...state, loadingPolicies: action.payload };
    default:
      throw new Error(`Invalid action in user info context`, action);
  }
};

const UserInfoContext = React.createContext<ContextProps | undefined>(
  undefined
);

const UserInfoProvider = ({ children }: UserInfoProviderProps) => {
  const initialState = {
    user: {},
    userPetPolicies: [],
    loadingPolicies: false,
    approvedRenewalPoliciesId: [],
  };
  const [state, dispatch] = React.useReducer(UserInfoReducer, initialState);

  const value = { state, dispatch };

  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  );
};

const useUserInfo = () => {
  const context = React.useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error('useUserInfo must be used inside UserInfoProvider');
  }

  return context;
};

export { UserInfoProvider, useUserInfo };
